import axios from "@/axios";

export default {
  fetchNotificationTemplates({ commit },partnerId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`usernotification/getNotification/${partnerId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  saveNotificationTemplates({ commit },data) {
    
    return new Promise((resolve, reject) => {
      axios
        .post(`usernotification/save/${data.id}`,data.payload)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
