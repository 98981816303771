/*=========================================================================================
  File Name: modulePaymentRequestActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
  storePaymentRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("paymentRequests", data.obj, data.config)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  storeNewPaymentRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("paymentRequests", data.obj, data.config)
        .then(res => {
          resolve(res);
        })
        .catch(ex => {
          return reject(ex.response);
        });
    });
  },

  fetchPaymentRequestListByPartnerId({ commit }, partnerId) {
    return new Promise((resolve, reject) => {
      axios
        .get("paymentRequests/partner/" + partnerId)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchPaymentRequestListByReqTemId({ commit }, reqTemId) {
    return new Promise((resolve, reject) => {
      axios
        .get("paymentRequests/paymentRequestTemplate/" + reqTemId)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },



  fetchPaymentRequestListByPartnerIdWithFilter({ commit }, data) {
    
    const searchparam = data.searcParam;
    const partnerId = data.partnerId;
    return new Promise((resolve, reject) => {

      const params = new URLSearchParams(searchparam).toString();

      axios
        .get("paymentRequests/partnerWithFilter/" + partnerId + "?" + params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  exportPaymentRequestListByPartnerIdWithFilter({ commit }, data) {

    const searchparam = data.searcParam;
    const partnerId = data.partnerId;
    return new Promise((resolve, reject) => {

      const params = new URLSearchParams(searchparam).toString();

      axios
        .get("paymentRequests/partnerWithFilter/export/" + partnerId + "?" + params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },



  fetchPaymentRequestDetailById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("paymentRequests/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchCustomerPaymentRequestDetailById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("customers/paymentRequests/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchCustomerPaymentRequestDetailWithPartnerById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("customers/paymentRequestDetails/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  removeFileFromPaymentRequestByIdAndFileId({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `paymentRequests/${data.id}/file/${data.fileId}?fileName=${data.fileName}`
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  cancelPaymentRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put("paymentRequests/cancel/" + data.id, data.data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  resendPaymentRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put("paymentRequests/resend/" + data.id, data.data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchCustomerPaymentRequests({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("customers/paymentRequests/list" + params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },


  fetchAmountOrValueByStatusAndDate({ commit }, params) {

    return new Promise((resolve, reject) => {
      axios
        .get("paymentRequests/transcByCard" + params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchSuccessRateByDate({ commit }, params) {

    return new Promise((resolve, reject) => {
      axios
        .get("paymentRequests/getSuccessRate?date=" + params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  calculateOnPayLater({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("calculation", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getPlanByCustomPlanId({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`paymentPlans/${data}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getPlanByMerchantAndCustomPlanId({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`paymentPlans/${data.merchantId}/${data.customPlanId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  // saveProductFieldInPr
  saveProductFieldInPr({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("paymentRequests/saveProductFieldInPr", data.data, data.config)
        .then(res => {
          resolve(res);
        })
        .catch(ex => {
          return reject(ex.response);
        });
    });
  },

  registerBankTransferPost({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`paymentRequests/registerBankTransfer/${data.id}`, data.data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

};
