/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "../../http/axios/index.js";

export default {
  SET_BEARER(state, accessToken) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  },

  SET_PAYMENT_PAGE_HEADER(state, data) {
    if (data.paymentDetails.header) {
      state.page = {
        partnerName: data.paymentDetails.header.companyName,
        logo: data.paymentDetails.header.companyLogo,
        abn: data.paymentDetails.header.abn,
        address: data.paymentDetails.header.address,
        phoneNumber: data.paymentDetails.header.phoneNumber,
        email: data.paymentDetails.header.companyEmail,
        link: data.paymentDetails.header.link,
      };
    }else if(data.paymentDetails.paymentPage && data.paymentDetails.paymentPage.pageBranding){
      state.page = {
        partnerName: data.paymentDetails.paymentPage.pageBranding.companyName || null,
        logo: data.paymentDetails.paymentPage.pageBranding.merchantLogo || undefined,
        abn: data.paymentDetails.paymentPage.pageBranding.companyAbn || null,
        address: data.paymentDetails.paymentPage.pageBranding.companyAddress || null,
        phoneNumber: data.paymentDetails.paymentPage.pageBranding.companyPhone || null,
        email: data.paymentDetails.paymentPage.pageBranding.companyEmail || null,
        link: data.paymentDetails.paymentPage.pageBranding.companyWeb || null,
      };
    }
  },
  SET_PAYMENT_PAGE_HEADER_FOR_STATIC(state, data) {
    state.page = {
      partnerName: data.companyName,
      logo: data.companyLogo,
      abn: data.abn,
      address: data.address,
      phoneNumber: data.phoneNumber,
      email: data.companyEmail,
      link: data.link,
    };
  },
  SET_PAYMENT_PAGE_HEADER_CUSTOMER(state, data) {
    state.page = {
      partnerName: data.companyName,
      logo: data.companyLogo,
      abn: data.abn,
      address: data.address,
      phoneNumber: data.phoneNumber,
      email: data.companyEmail,
      link: data.link,
    };
  },
};
