/*=========================================================================================
  File Name: modulePaymentRequestActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
 fetchCardDetails({ commit }, data) {
  
    return new Promise((resolve, reject) => {
      axios
        .get("card/getCounts?date="+data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
    fetchOnboardDetailByOnboardId({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get("/onboarding/detail/" + id)
                .then(res => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err.response);
                });
        });
    },
    fetchOnboardDetailWithId({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get("onboarding/detail/review/" + id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

  fetchOnboardDetailWithMerchantId({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`onboarding/review/${data.onboardingId}/${data.merchantId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

    fetchLegalDetailWithId({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get("admin/legals/id/" + id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

  fetchPaymentRequestListByPartnerId({ commit }, partnerId) {
    return new Promise((resolve, reject) => {
      axios
        .get("paymentRequests/partner/" + partnerId)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPaymentRequestDetailById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("paymentRequests/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchCustomerPaymentRequestDetailById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("customers/paymentRequests/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchCustomerPaymentRequestDetailWithPartnerById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("customers/paymentRequestDetails/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  removeFileFromPaymentRequestByIdAndFileId({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `paymentRequests/${data.id}/file/${data.fileId}?fileName=${data.fileName}`
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  cancelPaymentRequest({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .put("paymentRequests/cancel/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  resendPaymentRequest({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .put("paymentRequests/resend/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchCustomerPaymentRequests({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("customers/paymentRequests/list" + params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};
