/*=========================================================================================
  File Name: moduleHistoryActions.js
  Description: Partner Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
import { reject } from "lodash";
import { promises } from "stream";

Vue.use(VueCookies);

export default {
  fetchStaffByPartnerId({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/partners/staff-list/" + id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  fetchStaffDetailsById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/partners/staff/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getThirdPartyToken({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`partners/getToken/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  sendCredentialsToStaff({ commit }, id) {
    return new Promise((resolve, reject) => {

      axios
        .put(`/partners/staff/sendCredentials/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateStaffDetailsById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch("/partners/staff/" + data.id, data.data, data.config)
        .then(res => {
          const user = JSON.parse(localStorage.getItem("userInfo"));
          if (
            res.data.data.userType === "admin" &&
            user._id === res.data.data._id
          ) {
            commit("UPDATE_USER_INFO", res.data.data, { root: true });
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createStaff({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("partners/staff", data.data, data.config)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteStaff({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/partners/staff/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPartnerDetailById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/partners/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchOnboardDetailWithOnboardId({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
          .get("/partners/" + id)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
    });
  },

  updatePartnerStatusCallbackApiKey({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch("/partners/stauts-callback-api-key/" + data.partnerId, {
          statusCallbackApiKey: data.statusCallbackApiKey
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getPaymentPlanByMerchatId({commit},data){
    const partnerId = data;
    return new Promise((reslove,reject)=>{
      axios.get(`/admin/partners/${partnerId}/plan`).then((response)=>{
        reslove(response)
      }).catch((err)=>{
        reject(err)
      })
    })
  },
  resetThirdPartyToken({commit},data){
    const partnerId = data;
    return new Promise((reslove,reject)=>{
      axios.get(`partners/resetUserToken/${partnerId}`).then((response)=>{
        reslove(response)
      }).catch((err)=>{
        reject(err)
      })
    })
  },

  updateTestSettingStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post("/partners/update-test-setting-status", data)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        });
    })
  },
  getPayLaterProductsByPartnerId({commit},data){
    const partnerId = data;
    return new Promise((reslove,reject)=>{
      axios.get(`/partners/${partnerId}/paylaterProducts`).then((response)=>{
        reslove(response)
      }).catch((err)=>{
        reject(err)
      })
    })
  },

  submitQuickSetup({commit},data){
    return new Promise((reslove,reject)=>{
      axios.post(`/partners/${data.partnerId}/submitQuickSetup`, data.data, data.config).then((response)=>{
        reslove(response)
      }).catch((err)=>{
        reject(err)
      })
    })
  },

  verifySetupToken ({ commit }, token){
    return new Promise((resolve, reject) => {
      axios
        .post(`partners/verifySetupToken/`,token)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  sendOtpCodeToMerchant ({ commit }, data){
    return new Promise((resolve, reject) => {
      axios
        .post(`partners/sendOtpCodeToMerchant/`,data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  verifyOtpCodeOfMerchant ({ commit }, data){
    return new Promise((resolve, reject) => {
      axios
        .post(`partners/vetifyOtpCode/`,data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  createLoginAccount ({ commit }, data){
    return new Promise((resolve, reject) => {
      axios
        .post(`partners/createLoginAccount/`,data)
        .then((response) => {

          if (response.data.data) {
            localStorage.setItem("user", JSON.stringify(response.data.data));

            // Set accessToken and refresh token
            localStorage.setItem("accessToken", response.data.data.token.accessToken);
            localStorage.setItem("refreshToken", response.data.data.token.refreshToken);
            localStorage.setItem("tokenExpiry", response.data.data.token.accessTokenExpiresIn);
            localStorage.setItem("loggedIn", "true");

            // Update user details
            commit("UPDATE_USER_INFO", response.data.data, { root: true });

            // Set bearer token in axios
            commit("SET_BEARER", response.data.data.token.accessToken);

            resolve(response);
          } else {
            reject({ message: response.data.message });
          }

        })
        .catch((err) => {
          reject(err);
        });
    });
  },


};
