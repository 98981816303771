import axios from "@/axios";


export default {

  fetchDefaultBranding({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`brandSettings/fetchDefaultBranding`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchBrandSettingsByPartner({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`brandSettings/${id}/fetchSettings`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateBrandSettingsByPartner({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`brandSettings/${data.id}/updateSettings`, data.colorsAndFonts)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

};
