import jwt from "../../http/requests/auth/jwt/index.js";
import router from "@/router";
import httpStatusCode from "http-status-codes";
import axios from "@/axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
import { clearLocalStorage } from "../../helper/localStorage.js";
import { use } from "vee-validate/dist/vee-validate.minimal.esm";

Vue.use(VueCookies);

export default {
  updateUsername({ commit }, payload) {
    payload.user
      .updateProfile({
        displayName: payload.displayName
      })
      .then(() => {
        // If username update is success update in localstorage
        let newUserData = Object.assign({}, payload.user.providerData[0]);
        newUserData.displayName = payload.displayName;
        commit("UPDATE_USER_INFO", newUserData, { root: true });

        // If reload is required to get fresh data after update reload current page
        if (payload.isReloadRequired) {
          router.push(router.currentRoute.query.to || "/");
        }
      })
      .catch(ex => {
        payload.notify({
          time: 8800,
          title: "Error",
          text: ex.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      });
  },

  // login admin
  loginJWT({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .login(payload)
        .then(response => {
          if (response.data) {
            localStorage.setItem("user", JSON.stringify(response.data.data));

            // Set accessToken and refresh token
            localStorage.setItem("accessToken", response.data.data.token.accessToken);
            localStorage.setItem("refreshToken", response.data.data.token.refreshToken);
            localStorage.setItem("tokenExpiry", response.data.data.token.accessTokenExpiresIn);
            localStorage.setItem("loggedIn", "true");

            // set remember me
            if (payload.rememberMe === true) {
              Vue.$cookies.set("rememberMe", payload.rememberMe);
              Vue.$cookies.set("rememberMeToken", response.data.data.token.rememberMeToken);
            } else {
              Vue.$cookies.remove("rememberMe");
              Vue.$cookies.remove("rememberMeToken");
            }

            // Update user details
            commit("UPDATE_USER_INFO", response.data.data, { root: true });

            // Set bearer token in axios
            commit("SET_BEARER", response.data.data.token.accessToken);

            router.go("/admin/dashboard");
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.message });
        });
    });
  },

  // Partner Login Auth
  partnerLoginJWT({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .partnerLogin(payload)
        .then(response => {
          if (response.data.data) {
            localStorage.setItem("user", JSON.stringify(response.data.data));

            // Set accessToken and refresh token
            localStorage.setItem("accessToken", response.data.data.token.accessToken);
            localStorage.setItem("refreshToken", response.data.data.token.refreshToken);
            localStorage.setItem("tokenExpiry", response.data.data.token.accessTokenExpiresIn);
            localStorage.setItem("loggedIn", "true");

            // set remember me
            if (payload.rememberMe === true) {
              Vue.$cookies.set("rememberMe", payload.rememberMe);
              Vue.$cookies.set("rememberMeToken", response.data.data.token.rememberMeToken);
            } else {
              Vue.$cookies.remove("rememberMe");
              Vue.$cookies.remove("rememberMeToken");
            }

            // Update user details
            commit("UPDATE_USER_INFO", response.data.data, { root: true });

            // Set bearer token in axios
            commit("SET_BEARER", response.data.data.token.accessToken);

            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.message });
        });
    });
  },

  // Login As Partner
  loginAsPartner({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .LoginAs(payload)
        .then(response => {
          if (response.data.data) {
            localStorage.setItem("user", JSON.stringify(response.data.data));

            // Set accessToken and refresh token
            localStorage.setItem("accessToken", response.data.data.token.accessToken);
            localStorage.setItem("refreshToken", response.data.data.token.refreshToken);
            localStorage.setItem("tokenExpiry", response.data.data.token.accessTokenExpiresIn);
            localStorage.setItem("loggedIn", "true");

            //set remember me
            if (payload.rememberMe === true) {
              Vue.$cookies.config("30d");
              Vue.$cookies.set("rememberMe", payload.rememberMe);
              Vue.$cookies.set("email", payload.userDetails.email);
              Vue.$cookies.set("password", payload.userDetails.password);
            } else {
              Vue.$cookies.remove("rememberMe");
              Vue.$cookies.remove("email");
              Vue.$cookies.remove("password");
            }

            // Update user details
            commit("UPDATE_USER_INFO", response.data.data, { root: true });

            // Set bearer token in axios
            commit("SET_BEARER", response.data.data.token.accessToken);

            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.message });
        });
    });
  },

  registerUserJWT({ commit }, payload) {
    const {
      displayName,
      email,
      password,
      confirmPassword,
      contactNumber,
      userType
    } = payload.userDetails;

    return new Promise((resolve, reject) => {
      if (password !== confirmPassword) {
        reject({ message: "Password doesn't match. Please try again." });
      }

      if (userType === "franchise") {
        jwt
          .registerFranchise(payload.userDetails)
          .then(response => {
            // Redirect User
            router.push(router.currentRoute.query.to || "/");

            // Update data in localStorage
            localStorage.setItem("accessToken", response.data.accessToken);
            commit("UPDATE_USER_INFO", response.data.userData, { root: true });

            resolve(response);
          })
          .catch(ex => {
            reject(ex);
          });
      } else {
        jwt
          .registerUser(displayName, email, password, userType)
          .then(response => {
            // Redirect User
            router.push(router.currentRoute.query.to || "/");

            // Update data in localStorage
            localStorage.setItem("accessToken", response.data.accessToken);
            commit("UPDATE_USER_INFO", response.data.userData, { root: true });

            resolve(response);
          })
          .catch(ex => {
            reject(ex);
          });
      }
    });
  },

  fetchAccessToken() {
    return new Promise((resolve, reject) => {
      jwt.refreshToken().then(response => {
        resolve(response);
      }).catch(ex => {
        if (localStorage.getItem("accessToken")) {
          clearLocalStorage();
          router.push("/").catch(() => {});
        }

        reject(ex);
      });
    });
  },

  resetPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .resetPassword(payload.userDetails.password, payload.userDetails.token)
        .then(response => {
          // If there's user data in response
          if (response.status == httpStatusCode.OK) {

            // router.push('/admin/dashboard')
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {

          reject({ message: response.data.message });
        });
    });
  },

  resetPasswordByAdmin({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .resetAdminPassword(
          payload.userDetails.password,
          payload.userDetails.token
        )
        .then(response => {
          // If there's user data in response
          if (response.status == httpStatusCode.OK) {

            // router.push('/admin/dashboard')
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {

          reject({ message: response.data.message });
        });
    });
  },

  forgotPwd({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .forgotPasswordAdmin(payload.userDetail.email)
        .then(response => {
          if (response.data) {
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.message });
        });
    });
  },

  /**
   * admin verification mail resend
   * @param commit
   * @param payload
   * @returns {Promise<unknown>}
   */
  resendVerificationMail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .resendVerificationMailAdmin(payload.userDetail.email)
        .then(response => {
          if (response.data) {

            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.message });
        });
    });
  },

  changeUserPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .changePassword(
          payload.userDetails.currentPassword,
          payload.userDetails.newPassword
        )
        .then(response => {
          if (response.status == httpStatusCode.OK) {
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.message });
        });
    });
  },

  checkTokenExpiry({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .checkTokenExpiry(payload.token)
        .then(response => {
          if (response.status == httpStatusCode.OK) {
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.message });
        });
    });
  },

  isResetTokenExpired({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .checkResetTokenExpiry(payload.token)
        .then(response => {
          if (response.status === httpStatusCode.OK) {
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.message });
        });
    });
  },

  verifyMail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .verifyMail(payload.token)
        .then(response => {
          if (response.status == httpStatusCode.OK) {
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.message });
        });
    });
  },

  verifyAccount({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/user/verifyAccount", payload)
        .then(res => { return resolve(res); })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

  getUserByRememberMeToken({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchUserByRememberMeToken(payload)
        .then(response => {
          if (response.data) {
            localStorage.setItem("user", JSON.stringify(response.data.data._doc));

            // Set accessToken and refresh token
            localStorage.setItem("accessToken", response.data.data.token.accessToken);
            localStorage.setItem("refreshToken", response.data.data.token.refreshToken);
            localStorage.setItem("tokenExpiry", response.data.data.token.accessTokenExpiresIn);
            localStorage.setItem("loggedIn", "true");

            // Update user details
            commit("UPDATE_USER_INFO", response.data.data._doc, { root: true });

            // Set bearer token in axios
            commit("SET_BEARER", response.data.data.token.accessToken);

            resolve(response);
          }
        })
        .catch(ex => {
          return reject(ex.response);
        });
    });
  }
};
