import axios from "@/axios";

export default {
  storeApiLogRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post("apiLog/add", data).then(res => {
        resolve(res);
      })
      .catch(ex => {
        reject(ex);
      });
    });
  },

  storeApiLogResponse({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.patch("apiLog/update/" + data.uid, data.data).then(res => {
        resolve(res);
      })
      .catch(ex => {
        reject(ex);
      });
    });
  },
};
