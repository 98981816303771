/*=========================================================================================
  File Name: moduleCommunicationGlobalSetting.js
  Description: Communication Global Setting Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from "./moduleCommunicationGlobalSettingState.js";
import mutations from "./moduleCommunicationGlobalSettingMutation.js";
import actions from "./moduleCommunicationGlobalSettingActions.js";
import getters from "./moduleCommunicationGlobalSettingGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
