/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";
import store from "./store/store";
import { auth } from "@/auth/auth";


Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/admin",
      redirect: "/admin/dashboard",
      name: "Admin",
      meta: {
        rule: "admin",
      },
      component: () => import("./layouts/main/Main.vue"),
      children: [
        {
          path: "dashboard",
          name: "Home",
          component: () => import("./views/DashboardAnalytics.vue"),
          meta: {
            pageTitle: "Dashboard",
            rule: "admin",
            requiresAuth: true,
          },
        },
        {
          path: "cms-pages",
          name: "CMSPages",
          component: () => import("./views/cms-pages/CMSPage.vue"),
          meta: {
            pageTitle: "Pages",
            rule: "admin",
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Pages List", active: true },
            ],
            requiresAuth: true,
          },
        },
        {
          path: "cms-pages/:id",
          name: "CMSPageDetails",
          component: () => import("./views/cms-pages/CMSPageDetails.vue"),
          meta: {
            pageTitle: "Page Details",
            rule: "admin",
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              // { title: "Page Management", url: "#" },
              { title: "Pages List", url: "/admin/cms-pages" },
              { title: "Page Detail", active: true },
            ],
            requiresAuth: true,
          },
        },
        {
          path: "/admin/profile",
          name: "admin-profile",
          component: () => import("@/views/pages/adminProfile.vue"),
          meta: {
            rule: "admin",
            requiresAuth: true,
          },
        },
        {
          path: "/admin/my-details",
          name: "my-details",
          component: () => import("@/views/cms-pages/MyDetails.vue"),
          meta: {
            rule: "admin",
          },
        },
        {
          path: "/admin/email-template",
          name: "email-template",
          component: () => import("@/views/cms-pages/email/EmailTemplate.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin" },
              { title: "Email Templates", active: true },
            ],
            pageTitle: "Email Templates",
            rule: "superAdmin",
          },
        },
        {
          path: "/admin/email-template/:id",
          name: "edit-email-template",
          component: () =>
            import("./views/cms-pages/email/EditEmailTemplate.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin" },
              { title: "Email Templates", url: "/admin/email-template" },
              { title: "Template", active: true },
            ],
            pageTitle: "Email Template",
            rule: "superAdmin",
          },
        },
        {
          path: "/payment-activity",
          name: "payment-activity",
          component: () => import("./views/pages/partners/PaymentActivity"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Payment activity", active: true },
            ],
            pageTitle: "Payment activity",
            rule: "admin",
            requiresAuth: true,
          },
        },
        {
          path: "/quick-setup",
          name: "quick-setup",
          component: () => import("./views/pages/partners/quickSetup/welcomePage.vue"),
          meta: {
            pageTitle: "Quick start",
            rule: "admin"
          },
        },
        {
          path: "/notifications",
          name: "notification-list",
          component: () => import("./views/pages/NotificationList"),
          meta: {
            pageTitle: "Notifications",
            rule: "admin",
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Notifications", active: true },
            ],
            //'authRequired': true
          },
        },
        {
          path: "notification-list",
          name: "notification-list",
          component: () => import("./views/notifications/NotificationList.vue"),
          meta: {
            // pageTitle: "Dashboard",
            rule: "admin",
            requiresAuth: true,
          },
        },
      ],
    },
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          redirect: "/login",
        },
        {
          path: "/dashboard",
          name: "partner-dashboard",
          component: () => import("@/views/pages/partners/Dashboard.vue"),
          meta: {
            requiresAuth: true,
            rule: "editor",
          },
        },

        {
          path: "/new-payment-requests/:reqTemId/create",
          name: "payment-request-create",
          component: () =>
            import(
              "@/views/pages/partners/pages/paymentRequests/paymentRequestForm.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Send request", active: true },
            ],
            pageTitle: "Send request",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/payment-requests/:id/show",
          name: "staff-payment-requests-detail",
          component: () =>
            import(
              "@/views/pages/partners/paymentRequest/PaymentRequestDetails/PaymentRequestDetails.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Payment activity", url: "/payment-activity" },
              { title: "View payment request", active: true },
            ],
            pageTitle: "View payment request  ",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/payment-request-template-communication",
          name: "payment-request-template-communication",
          component: () =>
            import("@/views/settings/merchant/PaymentRequestCommunication.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              {
                title: " Default communications settings - Payment requests",
                active: true,
              },
            ],
            pageTitle: "Default communications settings - Payment requests",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/hosted-page-communication",
          name: "hosted-page-communication",
          component: () =>
            import("@/views/settings/merchant/HostedPageCommunication.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              {
                title: "Default communications settings - Hosted payment pages",
                active: true,
              },
            ],
            pageTitle: "Default communications settings - Hosted payment pages",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/transactions",
          name: "staff-transactions",
          component: () =>
            import("@/views/pages/partners/transaction/Transaction.vue"),
          meta: {
            pageTitle: "Transactions",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/transactions/:id",
          name: "staff-transactions-details",
          component: () =>
            import("@/views/pages/partners/transaction/TransactionDetail.vue"),
          meta: {
            pageTitle: "Transaction",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/settings",
          name: "settings",
          component: () => import("@/views/settings/merchant/Settings.vue"),
          meta: {
            pageTitle: "Settings",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/payment-request-template",
          name: "payment-request-template",
          component: () =>
            import("@/views/settings/merchant/paymentRequestTemplateList.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Payment request templates", active: true },
            ],
            pageTitle: "Payment request templates",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/help",
          name: "help",
          component: () => import("@/views/settings/merchant/Help.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Help", active: true },
            ],
            pageTitle: "Help",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/request-expiry",
          name: "request-expiry",
          component: () => import("@/views/settings/merchant/Expiry.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Request expiry time", active: true },
            ],
            pageTitle: "Payment requests",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/hosted-page",
          name: "hosted-page",
          component: () => import("@/views/settings/merchant/HostedPage.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Hosted payment pages - pages", active: true },
            ],
            pageTitle: "Hosted Payment Pages",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/simple-payment-request-template/create/:templateType",
          name: "simple-payment-request-template-create",
          component: () =>
            import(
              "@/views/pages/partners/pages/paymentRequestTemplate/TemplateForm.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              {
                title: "Payment request templates",
                url: "/payment-request-template",
              },
              { title: "New simple request template", active: true },
            ],
            pageTitle: "New simple request template",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/advance-payment-request-template/create/:templateType",
          name: "advance-payment-request-template-create",
          component: () =>
            import(
              "@/views/pages/partners/pages/paymentRequestTemplate/TemplateForm.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              {
                title: "Payment request templates",
                url: "/payment-request-template",
              },
              { title: "New advanced request template", active: true },
            ],
            pageTitle: "New advanced request template",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/api-payment-request-template/create/:templateType",
          name: "api-payment-request-template-create",
          component: () =>
            import(
              "@/views/pages/partners/pages/paymentRequestTemplate/TemplateForm.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              {
                title: "Payment request templates",
                url: "/payment-request-template",
              },
              { title: "New api request template", active: true },
            ],
            pageTitle: "New api request template",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/simple-payment-request-template-edit/:id",
          name: "simple-payment-request-template-edit",
          component: () =>
            import(
              "@/views/pages/partners/pages/paymentRequestTemplate/TemplateForm.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              {
                title: "Payment request templates",
                url: "/payment-request-template",
              },
              { title: "Edit simple request template", active: true },
            ],
            pageTitle: "Edit simple request template",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/advance-payment-request-template-edit/:id",
          name: "advance-payment-request-template-edit",
          component: () =>
            import(
              "@/views/pages/partners/pages/paymentRequestTemplate/TemplateForm.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              {
                title: "Payment request templates",
                url: "/payment-request-template",
              },
              { title: "Edit advanced request template", active: true },
            ],
            pageTitle: "Edit advanced request template",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/api-payment-request-template-edit/:id",
          name: "api-payment-request-template-edit",
          component: () =>
            import(
              "@/views/pages/partners/pages/paymentRequestTemplate/TemplateForm.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              {
                title: "Payment request templates",
                url: "/payment-request-template",
              },
              { title: "Edit api custom template", active: true },
            ],
            pageTitle: "Edit api custom template",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/hosted-page/create",
          name: "hosted-page-create",
          component: () =>
            import(
              "@/views/pages/partners/pages/hostedPaymentPage/TemplateForm.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Hosted payment pages", url: "/hosted-page" },
              { title: "New hosted payment page", active: true },
            ],
            pageTitle: "New hosted payment page",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/hosted-page/:id",
          name: "hosted-page-edit",
          component: () =>
            import(
              "@/views/pages/partners/pages/hostedPaymentPage/TemplateForm.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Hosted payment pages", url: "/hosted-page" },
              { title: "Edit hosted payment page", active: true },
            ],
            pageTitle: "Edit hosted payment page",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/branding-page",
          name: "branding-page",
          component: () =>
            import("@/views/settings/merchant/defaultCompanyHeader.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              {
                title: "Branding & style - payment page branding ",
                active: true,
              },
            ],
            pageTitle: "Branding & style - payment page branding",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/branding-style",
          name: "branding-style",
          component: () =>
            import("@/views/settings/merchant/BrandingStyle.vue"),
          meta: {
            pageTitle: "Branding & style",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/branding-logo-library",
          name: "branding-logo-library",
          component: () => import("@/views/settings/merchant/LogoLibrary.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Branding & style - logo library", active: true },
            ],
            pageTitle: "Branding & style - logo library",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/custom-fields",
          name: "custom-fields",
          component: () =>
            import(
              "@/views/pages/partners/pages/paymentRequestTemplate/customFields.vue"
            ),
          meta: {
            pageTitle: "Custom Fields",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },

        {
          path: "/customer-portal",
          name: "customer-portal",
          component: () =>
            import("@/views/settings/merchant/CustomerPortal.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              {
                title: "Customer self-service portal - link to portal",
                active: true,
              },
            ],
            pageTitle: "Customer self-service portal - link to portal",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/customer-portal-branding",
          name: "customer-portal-branding",
          component: () =>
            import("@/views/settings/merchant/PortalBranding.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              {
                title: "Customer self-service portal - branding",
                active: true,
              },
            ],
            pageTitle: "Customer self-service portal - branding",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/default-company-header",
          name: "default-company-header",
          component: () =>
            import("@/views/settings/merchant/defaultCompanyHeader.vue"),
          meta: {
            pageTitle: "Company Header",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/pages/custom-field/create",
          name: "custom-field-create",
          component: () =>
            import(
              "@/views/pages/partners/pages/customFields/CustomFieldForm.vue"
            ),
          meta: {
            breadcrumb: [
              // { title: "Pages", url: "/settings" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Custom field",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/pages/custom-field/:id/edit",
          name: "custom-field-edit",
          component: () =>
            import(
              "@/views/pages/partners/pages/customFields/CustomFieldForm.vue"
            ),
          meta: {
            breadcrumb: [
              // { title: "Pages", url: "/settings" },
              { title: "Edit", active: true },
            ],
            pageTitle: "Custom field edit",
            requiresAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/users",
          name: "partner-users",
          component: () => import("@/views/pages/partners/users/ListUser.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Access & permissions - users", active: true },
            ],
            pageTitle: "Access & permissions - users",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },

        {
          path: "/users/create",
          name: "partner-users-create",
          component: () => import("@/views/pages/partners/users/UserForm.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Access & permissions - users", url: "/users" },
              { title: "New user", active: true },
            ],
            pageTitle: "New user",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/users/:id/view",
          name: "partner-users-view",
          component: () => import("@/views/pages/partners/users/UserView.vue"),
          meta: {
            pageTitle: "Users",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/users/:id/edit",
          name: "partner-users-edit",
          component: () => import("@/views/pages/partners/users/UserForm.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Access & permissions - users", url: "/users" },
              { title: "Edit user", active: true },
            ],
            pageTitle: "Edit user",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/groups",
          name: "partner-groups",
          component: () => import("@/views/pages/partners/users/ListGroup.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Access & permissions - groups", active: true },
            ],
            pageTitle: "Access & permissions - groups",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/groups/create",
          name: "merchant-group-create",
          component: () =>
            import("@/views/pages/partners/users/groupsForm.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Access & permissions - groups", url: "/groups" },
              { title: "New user group", active: true },
            ],
            pageTitle: "New user group",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },

        {
          path: "/groups/:id/edit",
          name: "merchant-group-edit",
          component: () =>
            import("@/views/pages/partners/users/groupsForm.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Settings", url: "/settings" },
              { title: "Access & permissions - groups", url: "/groups" },
              { title: "Edit user group", active: true },
            ],
            pageTitle: "Edit user group",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/view/profile",
          name: "admin-view-profile",
          component: () => import("@/views/pages/adminProfileNew.vue"),
          meta: {
            rule: "editor",
            pageTitle: "User profile ",
            requiresAuth: true,
          },
        },
        {
          path: "/linked-system",
          name: "linked-system",
          component: () => import("@/views/settings/merchant/LinkedSystem.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Integrations - linked systems", active: true },
            ],
            pageTitle: "Integrations - linked systems",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/linked-system/:id",
          name: "linked-system-detail",
          component: () =>
            import("@/views/settings/merchant/LinkedSystemDetail.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Integrations - linked systems", url: "/linked-system" },
              { title: "View", active: true },
            ],
            pageTitle: "View",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/bank-accounts",
          name: "bank-accounts",
          component: () => import("@/views/settings/merchant/BankAccounts.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Account configuration - bank accounts", active: true },
            ],
            pageTitle: "Account configuration - bank accounts",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/archived-data",
          name: "archived-data",
          component: () => import("@/views/settings/merchant/ArchivedApplications.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Account configuration - Archived data", active: true },
            ],
            pageTitle: "Account configuration",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/product-list",
          name: "merchant-product-list",
          component: () => import("@/views/settings/merchant/ProductList.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Account configuration - products", active: true },
            ],
            pageTitle: "Account configuration",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
        {
          path: "/payment-options",
          name: "payment-options",
          component: () =>
            import("@/views/settings/merchant/PaymentOptions.vue"),
          meta: {
            breadcrumb: [
              { title: "Settings", url: "/settings" },
              { title: "Account configuration - payment options", active: true },
            ],
            pageTitle: "Account configuration",
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },

        // =============================================================================
        // Pages Routes
        // =============================================================================

        {
          path: "/pages/user-settings",
          name: "page-user-settings",
          component: () =>
            import("@/views/pages/user-settings/UserSettings.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Pages" },
              { title: "User Settings", active: true },
            ],
            pageTitle: "Settings",
            rule: "editor",
          },
        },
        {
          path: "/pages/knowledge-base",
          name: "page-knowledge-base",
          component: () => import("@/views/pages/KnowledgeBase.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Pages" },
              { title: "KnowledgeBase", active: true },
            ],
            pageTitle: "KnowledgeBase",
            rule: "editor",
          },
        },
        {
          path: "/pages/knowledge-base/category",
          name: "page-knowledge-base-category",
          component: () => import("@/views/pages/KnowledgeBaseCategory.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Pages" },
              { title: "KnowledgeBase", url: "/pages/knowledge-base" },
              { title: "Category", active: true },
            ],
            parent: "page-knowledge-base",
            rule: "editor",
          },
        },
        {
          path: "/pages/knowledge-base/category/question",
          name: "page-knowledge-base-category-question",
          component: () =>
            import("@/views/pages/KnowledgeBaseCategoryQuestion.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Pages" },
              { title: "KnowledgeBase", url: "/pages/knowledge-base" },
              { title: "Category", url: "/pages/knowledge-base/category" },
              { title: "Question", active: true },
            ],
            parent: "page-knowledge-base",
            rule: "editor",
          },
        },

        // =============================================================================
        // CHARTS & MAPS
        // =============================================================================
        {
          path: "/charts-and-maps/charts/apex-charts",
          name: "extra-component-charts-apex-charts",
          component: () =>
            import("@/views/charts-and-maps/charts/apex-charts/ApexCharts.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Charts & Maps" },
              { title: "Apex Charts", active: true },
            ],
            pageTitle: "Apex Charts",
            rule: "editor",
          },
        },
        {
          path: "/charts-and-maps/charts/chartjs",
          name: "extra-component-charts-chartjs",
          component: () =>
            import("@/views/charts-and-maps/charts/chartjs/Chartjs.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Charts & Maps" },
              { title: "chartjs", active: true },
            ],
            pageTitle: "chartjs",
            rule: "editor",
          },
        },
        {
          path: "/charts-and-maps/charts/echarts",
          name: "extra-component-charts-echarts",
          component: () =>
            import("@/views/charts-and-maps/charts/echarts/Echarts.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Charts & Maps" },
              { title: "echarts", active: true },
            ],
            pageTitle: "echarts",
            rule: "editor",
          },
        },
        {
          path: "/charts-and-maps/maps/google-map",
          name: "extra-component-maps-google-map",
          component: () =>
            import("@/views/charts-and-maps/maps/google-map/GoogleMap.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Charts & Maps" },
              { title: "Google Map", active: true },
            ],
            pageTitle: "Google Map",
            rule: "editor",
          },
        },

        // =============================================================================
        // EXTENSIONS
        // =============================================================================
        {
          path: "/extensions/select",
          name: "extra-component-select",
          component: () =>
            import("@/views/components/extra-components/select/Select.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Extra Components" },
              { title: "Select", active: true },
            ],
            pageTitle: "Select",
            rule: "editor",
          },
        },
        {
          path: "/extensions/quill-editor",
          name: "extra-component-quill-editor",
          component: () =>
            import(
              "@/views/components/extra-components/quill-editor/QuillEditor.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Extra Components" },
              { title: "Quill Editor", active: true },
            ],
            pageTitle: "Quill Editor",
            rule: "editor",
          },
        },
        {
          path: "/extensions/drag-and-drop",
          name: "extra-component-drag-and-drop",
          component: () =>
            import(
              "@/views/components/extra-components/drag-and-drop/DragAndDrop.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Extra Components" },
              { title: "Drag & Drop", active: true },
            ],
            pageTitle: "Drag & Drop",
            rule: "editor",
          },
        },
        {
          path: "/extensions/datepicker",
          name: "extra-component-datepicker",
          component: () =>
            import(
              "@/views/components/extra-components/datepicker/Datepicker.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Extra Components" },
              { title: "Datepicker", active: true },
            ],
            pageTitle: "Datepicker",
            rule: "editor",
          },
        },
        {
          path: "/extensions/datetime-picker",
          name: "extra-component-datetime-picker",
          component: () =>
            import(
              "@/views/components/extra-components/datetime-picker/DatetimePicker.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Extra Components" },
              { title: "Datetime Picker", active: true },
            ],
            pageTitle: "Datetime Picker",
            rule: "editor",
          },
        },
        {
          path: "/extensions/i18n",
          name: "extra-component-i18n",
          component: () =>
            import("@/views/components/extra-components/I18n.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Extensions" },
              { title: "I18n", active: true },
            ],
            pageTitle: "I18n",
            rule: "editor",
          },
        },
        {
          path: "/extensions/carousel",
          name: "extra-component-carousel",
          component: () =>
            import("@/views/components/extra-components/carousel/Carousel.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Extensions" },
              { title: "Carousel", active: true },
            ],
            pageTitle: "Carousel",
            rule: "editor",
          },
        },
        {
          path: "/extensions/clipboard",
          name: "extra-component-clipboard",
          component: () =>
            import(
              "@/views/components/extra-components/clipboard/Clipboard.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Extensions" },
              { title: "Clipboard", active: true },
            ],
            pageTitle: "Clipboard",
            rule: "editor",
          },
        },
        {
          path: "/extensions/context-menu",
          name: "extra-component-context-menu",
          component: () =>
            import(
              "@/views/components/extra-components/context-menu/ContextMenu.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Extensions" },
              { title: "Context Menu", active: true },
            ],
            pageTitle: "Context Menu",
            rule: "editor",
          },
        },
        {
          path: "/import-export/import",
          name: "import-excel",
          component: () =>
            import(
              "@/views/components/extra-components/import-export/Import.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Extensions" },
              { title: "Import/Export" },
              { title: "Import", active: true },
            ],
            pageTitle: "Import Excel",
            rule: "editor",
          },
        },
        {
          path: "/import-export/export",
          name: "export-excel",
          component: () =>
            import(
              "@/views/components/extra-components/import-export/Export.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Extensions" },
              { title: "Import/Export" },
              { title: "Export", active: true },
            ],
            pageTitle: "Export Excel",
            rule: "editor",
          },
        },
        {
          path: "/import-export/export-selected",
          name: "export-excel-selected",
          component: () =>
            import(
              "@/views/components/extra-components/import-export/ExportSelected.vue"
            ),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Extensions" },
              { title: "Import/Export" },
              { title: "Export Selected", active: true },
            ],
            pageTitle: "Export Excel",
            rule: "editor",
          },
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/callback",
          name: "auth-callback",
          component: () => import("@/views/Callback.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/admin/login",
          name: "page-login",
          component: () => import("@/views/pages/login/Login.vue"),
          meta: {
            rule: "editor",
            requiresAuth: true,
          },
        },
        {
          path: "/login",
          name: "partner-login",
          component: () =>
            import("@/views/pages/login/partners/PartnerLogin.vue"),
          meta: {
            rule: "editor",
            requiresAuth: false,
          },
        },
        {
          path: "/login-as-partner",
          name: "login-as-partner",
          component: () => import("@/views/pages/login/LoginAsPartner.vue"),
          meta: {
            rule: "editor",
            // requiresAuth: true
          },
        },

        {
          path: "/google",
          name: "google-login",
          component: () => import("@/views/pages/login/GoogleLogin.vue"),
          meta: {
            rule: "editor",
            authRequired: false,
          },
        },
        {
          path: "/forgot-password",
          name: "admin-forgot-password",
          component: () => import("@/views/pages/ForgotPassword.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/resend-verification",
          name: "page-resend-verification",
          component: () => import("@/views/pages/ResendVerification"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/set-password/:token",
          name: "user-forgot-password",
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token,
            };
            store
              .dispatch("auth/checkTokenExpiry", payload)
              .then(() => {
                next();
              })
              .catch((error) => {
                next("/pages/error-419");
              });
          },
          component: () => import("@/views/pages/setPassword.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/verify-email/:token",
          name: "user-account-verification",
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token,
            };
            store
              .dispatch("auth/verifyMail", payload)
              .then(() => {
                return next();
                //next("/admin/login");
              })
              .catch((error) => {

                return next("/pages/error-419");
              });
          },
          component: () => import("@/views/pages/verificationSuccess.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/reset-password",
          name: "page-reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "editor",
            authRequired: false,
          },
        },
        {
          path: "/reset-password/:token",
          name: "user-reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "editor",
            authRequired: false,
          },
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token,
            };
            store
              .dispatch("auth/isResetTokenExpired", payload)
              .then(() => {
                next();
              })
              .catch((error) => {
                next("/pages/error-419");
              });
          },
        },
        {
          path: "/success",
          name: "reset-password-success",
          component: () => import("@/views/pages/Success.vue"),
          meta: {
            rule: "editor",
          },
        },

        {
          path: "/admin/reset-password",
          name: "reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "editor",
            authRequired: false,
          },
        },
        {
          path: "/admin/reset-password/:token",
          name: "admin-reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "editor",
            authRequired: false,
          },
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token,
            };
            store
              .dispatch("auth/isResetTokenExpired", payload)
              .then(() => {
                next();
              })
              .catch((error) => {
                next("/pages/error-419");
              });
          },
        },
        {
          path: "/pages/lock-screen",
          name: "page-lock-screen",
          component: () => import("@/views/pages/LockScreen.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/error-419",
          name: "page-error-419",
          component: () => import("@/views/pages/Error419.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/error-500",
          name: "page-error-500",
          component: () => import("@/views/pages/Error500.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/not-authorized",
          name: "page-not-authorized",
          component: () => import("@/views/pages/NotAuthorized.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/maintenance",
          name: "page-maintenance",
          component: () => import("@/views/pages/Maintenance.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/pages/privacy-policy/:slug",
          name: "PrivacyPolicy",
          component: () => import("@/views/pages/PrivacyPolicy.vue"),
          meta: {
            rule: "editor",
          },
        },
      ],
    },
    {
      path: "",
      component: () => import("@/layouts/Terms/guest.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/pages/terms-and-conditions/:id",
          name: "TermsAndConditions",
          component: () => import("@/views/pages/Terms.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/onboard/:onboardingId",
          name: "onboard-application",
          component: () => import("@/views/pages/partners/ReviewData"),
          meta: {
            breadcrumb: [
            ],
            requiresMerchantAdminAuth: true,
            rule: "editor",
          },
        },
      ]
    },
    {
      path: "",
      component: () => import("@/layouts/guest/guest.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/pages/terms-and-conditions/:id",
          name: "TermsAndConditions",
          component: () => import("@/views/pages/Terms.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/payments/:id",
          name: "payments",
          component: () => import("@/views/guest/newPayment.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Merchant" },
              { title: "Payments", active: true },
            ],
            rule: "editor",
          },
        },
        {
          path: "/:paymentRequestId",
          name: "paymentPage",
          component: () => import("@/views/guest/newPayment.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/newpayments/:id",
          name: "newpayments",
          component: () => import("@/views/guest/newPayment.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Merchant" },
              { title: "Payments", active: true },
            ],
            rule: "editor",
          },
        },
        {
          path: "/payment-request/merchant/:pageId",
          name: "static-page-payments",
          component: () => import("@/views/guest/new-static-payment.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/payment/success",
          name: "payment-success",
          component: () => import("@/views/pages/paymentSuccess.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/payment/error",
          name: "payment-error",
          component: () => import("@/views/pages/paymentError.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/payment/forbidden",
          name: "payment-forbidden",
          component: () => import("@/views/pages/PaymentForbidden.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/payment-legals/:id/:legalid",
          name: "paymentLegals",
          component: () => import("@/views/guest/legals.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/dashboard" },
              { title: "Merchant" },
              { title: "Legals", active: true },
            ],
            rule: "editor",
          },
        },

      ],
    },

    {
      path: "/thank-you/submit",
      name: "thankyou",
      component: () => import("@/views/ThankYouStatic.vue"),
      meta: {
        rule: "editor",
      },
    },

    {
      path: "/request/expire",
      name: "requestExpire",
      component: () => import("@/views/RequestExpire.vue"),
      meta: {
        rule: "editor",
      },
    },

    {
      path: "",
      name: "createLogin",
      component: () => import("@/layouts/CreateLogin/CreateLogin.vue"),

      children:[
        {
          path: "/:partnerId/authenticate",
          name: "authenticate",
          component: () => import("@/views/pages/authentication/authenticate.vue"),
          beforeEnter: (to, from, next) => {

            const payload = {
              partnerId: to.params.partnerId,
              hash:to.query.hash
            };
            store
              .dispatch("partner/verifySetupToken", payload)
              .then((response) => {
                const responeData = response.data.data;

                if(responeData.isTokenExpire){
                  return next(`request/expire`);
                }
                return next();

              })
              .catch(error => {
                return next(`request/expire`);
              });
          },
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/:partnerId/verify-otp",
          name: "verifyOtp",
          component: () => import("@/views/pages/authentication/verifyOtp.vue"),
          meta: {
            rule: "editor",
          },
        },
        {
          path: "/:partnerId/setup-credentials",
          name: "setupCredentials",
          component: () => import("@/views/pages/authentication/SetupCredentials.vue"),
          meta: {
            rule: "editor",
          },
        },
      ]
    },
    // Redirect to 404 page, if no match found
  ],
});

router.afterEach((to) => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
  document.title = to.meta.pageTitle || process.env.VUE_APP_NAME;
});

//this method is calling in each router navigate
//commented cause of reset-password link doesn't required to auth
router.beforeEach((to, from, next) => {
  if (
    to.matched.some(
      (record) => record.meta.requiresAuth
    )
  ) {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
    let quickStart = userInfo.partner && userInfo.partnerId ? userInfo.partner.quickStartCompleted : false;
    const firstPath = to.path.split("/")[1];
    if (firstPath === "admin" && userInfo && userInfo.role !== "admin") {
      return next({ path: "/dashboard/" });
    }
    if(!quickStart && !userInfo.adminId){
      return next({ path: "/quick-setup/" });
    }
  }
    auth(to, from, next);
  } else if (
    to.matched.some((record) => record.meta.requiresMerchantAdminAuth)
  ) {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
    let quickStart = userInfo.partner && userInfo.partnerId ? userInfo.partner.quickStartCompleted : false;
    if (userInfo.userType !== "admin" && userInfo.userType !== "staffAdmin") {
      return next({ path: "/dashboard/" });
    }
    if(!quickStart && !userInfo.adminId){
      return next({ path: "/quick-setup/" });
    }
  }
    auth(to, from, next);
  } else if (to.matched.some((record) => record.meta.customerAuthRequired)) {
    let customerInfo = JSON.parse(localStorage.getItem("customerInfo"));
    if (customerInfo) {
      return next();
    } else {
      const firstPath = to.path.split("/")[1];
      return next({ path: `${firstPath}/customer-login` });
    }
  } else {
    return next();
  }
});

export default router;
