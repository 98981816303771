import axios from "@/axios";

export default {
  fetchMerchantCommunicationSettingsByPartner({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`communicationSettings/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateMerchantCommunicationSettingsByPartner({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`communicationSettings/${data.id}`, data.payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
