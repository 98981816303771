/*=========================================================================================
  File Name: moduleCustomField.js
  Description: Auth Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from "./moduleCustomFieldState";
import mutations from "./moduleCustomFieldMutations";
import actions from "./moduleCustomFieldActions";
import getters from "./moduleCustomFieldGetters";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
