import axios from "@/axios";

export default {
  fetchCustomFieldsByPartnerId({ commit }, partnerId) {
    return new Promise((resolve, reject) => {
      axios
        .get("customFields/partner/" + partnerId)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchCustomFieldById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("customFields/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createCustomField({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("customFields", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateCustomFieldById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch("customFields/" + data.id, data.data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteCustomFieldById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("customFields/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};
