/*=========================================================================================
  File Name: moduleLinkRequest.js
  Description: LinkRequest Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleLinkRequestState.js'
import mutations from './moduleLinkRequestMutations.js'
import actions from './moduleLinkRequestActions.js'
import getters from './moduleLinkRequestGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
