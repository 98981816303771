/**
 * This helper function uses to check the data type and property in objects
 * Depedent on lodash libraray
 * Reason : currently using lodash library , in future can be updated to some another better library
 */
 import lodash from 'lodash';

 export default {
     checkObjectKey : (object,path,defaultVal ={})=>{
         return lodash.get(object, path, defaultVal);
     },
     test:()=>{
         return "From Utility";
     }
 
 }
  