import state from "./moduleApiLogState";
import mutations from "./moduleApiLogMutations";
import actions from "./moduleApiLogActions";
import getters from "./moduleApiLogGetters";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
