import axios from "@/axios";

export default {
  fetchAccountSettingsByPartner({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("partners/account-settings/" + id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchMerchantNotificationSettingsByPartner({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`merchantSettings/${id}/notification`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateMerchantNotificationSettingsByPartner({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`merchantSettings/${data.id}/notification`, data.payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateAccountSetting({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `partners/account-settings/${data.partnerId}`,
          data.data,
          data.config
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchListSettingByPage({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`listSetting/${data.partnerId}/?name=${data.name}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveListSetting({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("listSetting/", data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updatelistSetting({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`listSetting/${data.partnerId}/?name=${data.name}`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchPartnerDetailById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/merchantSettings/merchant/" + id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

  checkEnabledPortal({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.get("/admin/setting/portal/" + data).then(res => {
        return resolve(res);
      }).catch(ex => {
        return reject(ex.response);
      });
    });
  }
};
