/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";
export default {
  storeTransaction({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("transactions", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchTransactionListByPartnerId({ commit }, partnerId) {
    return new Promise((resolve, reject) => {
      axios
        .get("transactions/partner/" + partnerId)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchTransactionListByPartnerIdWithFilter({ commit }, data) {
    const searchparam = data.searcParam;
    const partnerId = data.partnerId;
    const params = new URLSearchParams(searchparam).toString();
    return new Promise((resolve, reject) => {
      axios
        .get("transactions/partnerWithFilter/"+partnerId+"?"+params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  exportTransactionListByPartnerIdWithFilter({ commit }, data) {
    const searchparam = data.searcParam;
    const partnerId = data.partnerId;
    const params = new URLSearchParams(searchparam).toString();
    return new Promise((resolve, reject) => {
      axios
        .get("transactions/partnerWithFilter/export/"+partnerId+"?"+params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchTransactionById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`transactions/partner/${data.partnerId}/${data.transactionId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  refundPaymentRequestTransaction({commit}, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("transactions/refund", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
