import axios from "@/axios";


export default {

  fetchRequestTemplates({ commit }, merchantId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`requestTemplate/${merchantId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  createRequestTemplate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`requestTemplate/create`, data.data, data.config)
        .then((res) => {
          resolve(res);
        })
        .catch((ex) => {
          return reject(ex.response);
        });
    });
  },

  fetchRequestTemplateById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`requestTemplate/${id}/fetch`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchRequestTemplateByIdOnMerchant({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`requestTemplate/${id}/auth`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchRequestTemplateByWithCards({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`requestTemplate/${id}/fetchWithCards`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },



  fetchRequestTemplateByMerchantId({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`requestTemplate/${data.merchantId}/${data.type}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchRequestTemplateByMerchantIdandGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`requestTemplate/byGroup/${data.merchantId}/${data.type}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchUnassignedTemplate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`requestTemplate/${data.merchantId}/unassigned/${data.type}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchUnassignedTemplateById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`requestTemplate/${data.merchantId}/unassigned/${data.groupId}/${data.type}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateRequestTemplateById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`requestTemplate/${data.id}/updateStatus`, data.payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },

  deleteRequestTemplateById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`requestTemplate/${data.id}/delete`, data.payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchRequestTemplateByIdandStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`requestTemplate/byStatus/${data.templateId}/${data.status}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

};
