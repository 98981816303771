import state from "./customerPortalSettingState";
import mutations from "./customerPortalSettingMutation";
import actions from "./customerPortalSettingActions";
import getters from "./customerPortalSettingGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
