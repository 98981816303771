import axios from "@/axios";

export default {
  fetchAllPlans({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("admin/payment-plans/?page=1&limit=100&sort=desc")
        .then(res => {
          commit("GET_ALL_PLANS", res.data.data.docs);
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  calculateOnPayLater({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("calculation", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  deletePlan({ commit },id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("admin/payment-plans/"+id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updatePartnerPlans({ commit },data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`partners/${data.id}/product-display-name`,data.data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
