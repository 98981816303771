import axios from "@/axios";


export default {

  fetchPortalSettingsByMerchantId({ commit }, merchantId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`customerPortal/${merchantId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updatePortalSetting({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`customerPortal/${data.id}`, data.payload)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

};
