import axios from "@/axios";


export default {

  fetchLogosByMerchantId({ commit }, merchantId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`logos/getLogoUI/${merchantId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchLogoByObjectId({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`logos/getLogoByObjectId/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },


  uploadLogosByMerchantId({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`logos/uploadLogoUI/${data.id}`, data.data, data.config)
        .then(res => {
          resolve(res);
        })
        .catch(ex => {
          return reject(ex.response);
        });
    });
  },

  saveListSetting({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("listSetting/", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updatelistSetting({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`listSetting/${data.partnerId}/?name=${data.name}`, data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateLogoById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`logos/updateLogoUI/${data.logoId}/${data.merchantId}`, data.data, data.config)
        .then(res => {
          resolve(res);
        })
        .catch(ex => {
          return reject(ex.response);
        });
    });
  },

  removeLogo({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post("logos/deleteLogoUI/", data).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  },

};
