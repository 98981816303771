import axios from "@/axios";

export default {
  upsertLegal({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("admin/legals", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchAllLegals({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/legals/"+params)
        .then(res => {
          commit("SET_LEGALS", res.data.data);
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

  fetchLegalsById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/admin/legals/id/"+id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
fetchProductLegalsById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
          .get("/paymentPlans/product-legals/"+id)
          .then(res => {
            return resolve(res);
          })
          .catch(err => {
            return reject(err.response);
          });
    });
  }
};
