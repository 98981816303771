/*=========================================================================================
  File Name: moduleCustomer.js
  Description: Auth Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from "./moduleCustomerState";
import mutations from "./moduleCustomerMutations";
import actions from "./moduleCustomerActions";
import getters from "./moduleCustomerGetters";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
