import axios from "../../http/axios/index.js"

export default {
  SET_BEARER(state, accessToken) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  },

  GET_ALL_PLANS(state, payload) {
    state.paymentPlans = payload;
  },

  DELETE_PLAN(state, payload) {
    state.paymentPlans = state.paymentPlans.filter(item => item._id !== payload);
  },

  ADD_PLAN(state, payload) {
    state.paymentPlans.push(payload);
  },

  UPDATE_PLAN(state, payload) {
    const index = state.paymentPlans.findIndex(topic => topic._id === payload._id);

    if (index !== -1) {
      state.paymentPlans.splice(index, 1, payload);
    }
  }
};
