/*=========================================================================================
  File Name: brandSetting.js
  Description: Brand Setting
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from "./brandSettingState";
import mutations from "./brandSettingMutation";
import actions from "./brandSettingActions";
import getters from "./brandSettingGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
