/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
  getPaymentDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.get("/payment/details?transactionId=" + id).then(res => {
        if (res.data.data) {
          commit("SET_PAYMENT_PAGE_HEADER", res.data.data);
        }
        return resolve(res);
      }).catch(err => {
        return reject(err);
      });
    });
  },

  getPaymentPageDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/payment/pageDetails?pageId=" + id)
        .then(res => {
          if (res.data.data)
            commit(
              "SET_PAYMENT_PAGE_HEADER_FOR_STATIC",
              res.data.data.paymentPageDetails
            );
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  makePayment({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/payment/make", data.obj, data.config)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  makeStaticPayment({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/payment/makeStatic", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

  getQuotationDataForPayLater({commit}, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/calculation", data)
        .then(res => {

          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  sendConfirmation({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/payment/confirmation", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  sendFailedConfirmation({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/payment/failedConfirmation", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  sendDeviceVerificationCodeForPaylater({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/payment/device/sendCode", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  verifyDeviceForPaylater({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/payment/device/verify", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  sendLoginOTPCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/customers/sendOtpCode", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  verifyLoginOTPCode({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/customers/submitOtp", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  verifyIDMatrix({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/payment/verifyID/${data.id}`, data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  verifyCustomerEmail({commit}, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/payment/verifyCustomer`, data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  getCustomerCompanyDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("customers/company-details", data)
        .then(res => {
          commit("SET_PAYMENT_PAGE_HEADER_CUSTOMER", res.data.data)
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  login({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/login`, data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  submitOTP({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/otp`, data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  resendOTP({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`customers/resend-otp`, data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  sendEmailVerificationCodeForPaylater({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/payment/email/sendCode", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  verifyEmailForPaylater({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/payment/email/verify", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },
  getProductCards({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/productCard" )
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

};
