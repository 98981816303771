export default {
  getLegalByType: state => type => {
    const legal = state.legals.find(legal => legal.type === type);
    if (legal) {
      const { content, header } = legal;
      return { content, header };
    }
    return { content: "", header: "" };
  }
};
