/*=========================================================================================
  File Name: moduleHistoryActions.js
  Description: Partner Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
import { reject } from "lodash";
import { promises } from "stream";
Vue.use(VueCookies);

export default {
  fetchHistoryListWithFilter({ commit }, data) {
    const searchparam = data.searcParam;
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams(searchparam).toString();

      axios
        .get("history/historyWithFilter/" +"?" + params)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },


  fetchHistoryListForDashbaord({ commit },params) {
    return new Promise((resolve, reject) => {

      axios
        .get(`history/filterWithNotifySetting/${params.type}?type=${params.notificationType}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },


  dismisWithUserId({ commit },id) {
    return new Promise((resolve, reject) => {

      axios
        .get("history/dismisWithUserId/"+id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },






};
