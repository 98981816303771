/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex);

import moduleAdmin from "./admin/moduleAdmin.js";
import moduleHistory from "./history/moduleHistory";
import moduleAuth from "./auth/moduleAuth.js";
import modulePage from "./page/modulePage.js";
import moduleUser from "./user-management/moduleUserManagement.js";
import moduleTransaction from "./transaction/moduleTransaction.js";
import modulePayment from "./payment/modulePayment";
import modulePaymentRequest from "./paymentRequest/modulePaymentRequest";
import modulePaymentType from "./paymentType/modulePaymentType";
import moduleBank from "./bank/moduleBank";
import moduleCompany from "./company/moduleCompany";
import modulePartner from "./partner/modulePartner";
import moduleConnector from "./connector/moduleConnector";
import moduleCustomField from "./customField/moduleCustomField";
import legalModule from "./legals/moduleLegal";
import paymentPlanModule from "./paymentplans/modulePaymentPlans";
import customerModule from "./customers/moduleCustomer";
import listSettingsModule from "./merchantSettings/moduleListSetting";
import merchantGlobalSettingModule from "./merchantGlobalSetting/merchantGlobalSetting";
import logoModule from "./logo/logo";

import merchantSettings from "./merchantSettings/moduleListSetting";
import  partnerDashboard from "./partnerDashboard/moduleDashboardRequest";
import communicationGlobalSetting from "./communicationGlobalSetting/moduleCommunicationGlobalSetting";
import customerPortalSetting from "./customerPortalSetting/customerPortalSetting";
import brandSetting from "./brandSetting/brandSetting";
import paymentRequestTemplate from "./paymentRequestTemplate/paymentRequestTemplate";
import merchantGroupModule from "./merchantGroup/merchantGroup";
import userNotificationModule from "./userNotification/moduleUserNotification";
import linkRequestModule from "./linkRequest/moduleLinkRequest";
import paymentcardOptionModule from "./paymentcardOptions/modulePaymentcardOption"
import s3Service from "./s3Service/moduleS3Service"
import moduleApiLog from "./apiLog/moduleApiLog";

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    admin: moduleAdmin,
    page: modulePage,
    user: moduleUser,
    paymentRequest: modulePaymentRequest,
    transaction: moduleTransaction,
    payment: modulePayment,
    paymentType: modulePaymentType,
    bank: moduleBank,
    company: moduleCompany,
    partner: modulePartner,
    connector: moduleConnector,
    customField: moduleCustomField,
    legals: legalModule,
    paymentPlans: paymentPlanModule,
    customer: customerModule,
    listSetting: listSettingsModule,
    merchantGlobalSetting: merchantGlobalSettingModule,
    logo: logoModule,
    merchantSettings: merchantSettings,
    partnerDashboard: partnerDashboard,
    communicationGlobalSetting: communicationGlobalSetting,
    customerPortalSetting: customerPortalSetting,
    brandSetting: brandSetting,
    paymentRequestTemplate: paymentRequestTemplate,
    merchantGroup: merchantGroupModule,
    history: moduleHistory,
    userNotification: userNotificationModule,
    linkRequest: linkRequestModule,
    paymentcardOption:paymentcardOptionModule,
    s3ServiceModule: s3Service,
    apiLog: moduleApiLog,
  },
  strict: process.env.NODE_ENV !== "production",
});
