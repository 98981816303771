/*=========================================================================================
  File Name: moduleHistory.js
  Description: Partner Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from "./moduleHistoryState.js";
import mutations from "./moduleHistoryMutations.js";
import actions from "./moduleHistoryActions.js";
import getters from "./moduleHistoryGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
