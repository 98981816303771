import state from "./merchantGroupState";
import mutations from "./merchantGroupMutation";
import actions from "./merchantGroupActions";
import getters from "./merchantGroupGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
