import state from "./moduleUserNotificationState.js";
import mutations from "./moduleUserNotificationMutations.js";
import actions from "./moduleUserNotificationActions.js";
import getters from "./moduleUserNotificationGetters";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
