import axios from "@/axios";

export default {
  fetchPages({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "admin/page?page=" +
            data.page +
            "&limit=" +
            data.limit +
            "&search=" +
            data.search +
            "&sort=" +
            data.sort +
            "&dir=" +
            data.dir
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPageDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("admin/page/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updatePageDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch("admin/page/" + data.id, data.info)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchPageDetailsBySlug({ commit }, slug) {
    return new Promise((resolve, reject) => {
      axios
        .get("page/findWithSlug?slug=" + slug)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPagesByPartnerId({ commit }, partnerId) {
    return new Promise((resolve, reject) => {
      axios
        .get("page/partner/" + partnerId)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPagesById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("page/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updatePageById({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch("page/" + data.id, data.data, data.config)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createPage({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("page", data.data, data.config)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPaymentPlanByAmountAndPageId({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`page/${data.id}/payment-plans`, { amount: data.amount })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  savePageNotifications({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put("page/notifications/" ,data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
