import axios from "@/axios";


export default {

  fetchMerchantGroupByMerchantId({ commit }, merchantId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`merchantGroup/merchant/${merchantId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchMerchantAPIGroupByMerchantId({ commit }, merchantId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`merchantGroup/merchant/api-groups/${merchantId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchMerchantGroupById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`merchantGroup/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  saveMerchantGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`merchantGroup`, data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },


  updateMerchantGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`merchantGroup/${data.id}`, data.formData)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  deleteMerchantGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`merchantGroup/${data.id}/delete`, data.formData)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  updateAPIMerchantGroup({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`merchantGroup/api-group/${data.id}`, data.formData)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },


};
